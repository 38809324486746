import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { fetchMatchInfo } from "../helpers/index";
import { differenceInMilliseconds, intervalToDuration } from "date-fns";

import { getTextContent } from "../helpers/creative";

function SportElement({ element }) {
  const { style } = element;
  const [match, setMatch] = useState(element.sport.match);
  const intl = useIntl();

  function zeroDigit(num) {
    return num.toString().length === 1 ? `0${num}` : num;
  }

  const matchTimer = (date) => {
    const { start, end } = date;

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const startHours = parseInt(start.split(":")[0]);
    const startMinutes = parseInt(start.split(":")[1]);

    const endHours = parseInt(end.split(":")[0]);
    const endMinutes = parseInt(end.split(":")[1]);

    const startDate = new Date(
      currentYear,
      currentMonth,
      currentDay,
      startHours,
      startMinutes,
    );
    const endDate = new Date(
      currentYear,
      currentMonth,
      currentDay,
      endHours,
      endMinutes,
    );

    const diff = differenceInMilliseconds(endDate, startDate);
    const d = intervalToDuration({ start: 0, end: diff });

    return `${zeroDigit(d.minutes)}:${zeroDigit(d.seconds)}`;
  };

  // const getTiming = (match) => {
  //   const { details, status } = match;
  //
  //   if (status === 'Playing') {
  //     const periodState = details.period.state.find(
  //       (e) => e.id === details.period.id
  //     );
  //
  //     if (periodState) {
  //       if (periodState.start) {
  //         return matchTimer(periodState);
  //       }
  //     }
  //     const lastPeriodPlaying = details.period.state.slice(-1)[0];
  //     return matchTimer(lastPeriodPlaying);
  //   }
  //
  //   if (status === 'Played') {
  //     const lastPeriodPlayed = details.period.state.slice(-1)[0];
  //     return matchTimer(lastPeriodPlayed);
  //   }
  //
  //   return '00:00';
  // };
  //
  const getContent = (style) => {
    if (!match) {
      return null;
    }

    const { feature } = element;

    let content = "";

    switch (feature) {
      // case 'resultFootball':
      //   content = <FootballScore style={style} match={match} />;
      //   break;
      case "score":
        content = match.live
          ? `${match.details.home.score || "0"} - ${match.details.away.score || "0"}`
          : "0 - 0";
        break;
      case "penaltyScore":
        content = match.penalties
          ? `(${match.details.home.penaltyScore || "0"} - ${match.details.away.penaltyScore || "0"})`
          : "";
        break;
      case "matchTime":
        content = match.matchTime ? match.matchTime : "0:00";
        break;
      case "status":
        content = match.live
          ? intl.formatMessage({
              id: `sport.period.${match.details.period.id}`,
            })
          : intl.formatMessage({ id: "default.sport.match.status" });
        break;
      case "scoreHome":
        content = match.live ? `${match.details.home.score || "0"}` : "0";
        break;
      case "scoreAway":
        content = match.live ? `${match.details.away.score || "0"}` : "0";
        break;
      case "penaltyScoreHome":
        content = match.penalties
          ? `(${match.details.home.penaltyScore || "0"})`
          : "";
        break;
      case "penaltyScoreAway":
        content = match.penalties
          ? `(${match.details.away.penaltyScore || "0"})`
          : "";
        break;
      case "throwsHome":
        content = match.live ? `${match.details.home.shots || "0"}` : "0";
        break;
      case "throwsAway":
        content = match.live ? `${match.details.away.shots || "0"}` : "0";
        break;
      case "possesionHome":
        content = match.live
          ? `${match.details.home.possession || "0"}%`
          : "0%";
        break;
      case "possesionAway":
        content = match.live
          ? `${match.details.away.possession || "0"}%`
          : "0%";
        break;
      default:
        content = "";
        break;
    }

    return getTextContent({ ...element, content });
  };

  useEffect(() => {
    const fetchMatch = () => {
      const { sport } = element;
      try {
        if (sport.match.id) {
          fetchMatchInfo(sport.match.id)
            .then((json) => {
              setMatch(json);
            })
            .catch((err) => {
              throw new Error(err);
            });
        }
      } catch (error) {
        throw new Error(error);
      }
    };

    fetchMatch();

    const query = new URLSearchParams(window.location.search);
    const interval = query.get("livereload")
      ? parseInt(query.get("livereload"), 10) * 1000
      : 30 * 1000;

    const intervalSport = setInterval(fetchMatch, interval);

    return () => {
      clearInterval(intervalSport);
    };
  }, [element]);

  return (
    <div className={element.feature} style={style}>
      {getContent(style)}
    </div>
  );
}

SportElement.propTypes = {
  element: PropTypes.object,
};

export default SportElement;

