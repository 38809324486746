import { checkVisibilityRules } from "d3ck-lib";

import replaceSpecialCharacters from "replace-special-characters";
export const OPEN_INTERACTIVE_WS = "[websocket] Open Interactive WS";
export const OPEN_BROADSIGN_WS = "[websocket] Open BroadSign WS";
export const CLOSE_WEBSOCKET = "[websocket] Closed";
export const SEND_WEBSOCKET = "[websocket] Message";
export const ERROR_WEBSOCKET = "[websocket] Error";
export const RECONNECT_WEBSOCKET = "[websocket] Reconnet";
export const CONNECT_WEBSOCKET = "[websocket] Connect";

const broadSignUrl =
  process.env.BREACT_APP_BROADSIGNSOCKET || "http://localhost:2326";

const createCustomFieldVisibilitySlide = (slide, content) => ({
  visibleSlide: replaceSpecialCharacters(slide.name),
  visiblePlaylist: replaceSpecialCharacters(content),
});

const getSlideForPlaylist = (creative, playlists) => {
  const playlistName = playlists[0].id;
  const key = `${playlists[0]._id}_last-slide`;
  const rotationRaw = localStorage.getItem(key);
  const playlistRules = playlists[0].rules;

  let slideForPlaylist = creative.slides.find(
    (slide) => slide.anchor === rotationRaw,
  );
  if (slideForPlaylist) {
    slideForPlaylist = {
      ...slideForPlaylist,
      visibility: { ...slideForPlaylist.visibility, rules: playlistRules },
    };
  }

  return slideForPlaylist
    ? createCustomFieldVisibilitySlide(slideForPlaylist, playlistName)
    : { visiblePlaylist: undefined, visibleSlide: undefined };
};

export const listenBroadSignMessage = ({ ws, player, creative, playlists }) => {
  player = { ...player, askForData: true };
  const _id = creative ? creative._id : null;

  const slide = creative.slides.find((singleSlide) => {
    if (singleSlide.visibility.rules) {
      return checkVisibilityRules(singleSlide.visibility.rules, player);
    }
    return true;
  });

  let customFieldVisibilitySlide = {};

  if (creative.type !== "playlist") {
    customFieldVisibilitySlide = {
      visiblePlaylist: "",
      visibleSlide: replaceSpecialCharacters(slide.name),
    };
  } else {
    customFieldVisibilitySlide = getSlideForPlaylist(creative, playlists);
  }

  const customPOPstringified = JSON.stringify(customFieldVisibilitySlide);

  const message = {
    rc: {
      version: "1",
      id: "1",
      action: "custom_pop",
      frame_id: window.BroadSignObject?.frame_id || " ",
      content_id: window.BroadSignObject?.ad_copy_id || " ",
      external_value_1: "D3CK",
      external_value_2: creative.campaign._id,
      name: _id,
      custom_field: customPOPstringified,
    },
  };

  if (!creative.customPop) {
    return console.log("customPOP not activated");
  }

  return ws.send(JSON.stringify(message));
};

export const connectToBroadSign = () => {
  const ws = new WebSocket(broadSignUrl.replace(/^http/, "ws"));

  // ws.onopen = () => {
  //   console.log("connected websocket BROADSIGN main component");

  //   console.log("inside websocket 1.1,");
  // };

  ws.onerror = (err) => {
    console.error("Socket encountered BROADSIGN error: ", err);
  };

  return ws;
};
